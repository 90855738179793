@import 'variables';

.ant-table {
  border-radius: @border-radius-base @border-radius-base 0 0;
}

.ant-table-thead {
  background: linear-gradient(0deg, #fcfcff 0%, rgba(252, 252, 255, 0) 100%);
  & tr {
    & th {
      background: none;
    }
  }
}

.ant-pagination {
  margin: 0 !important;
  padding: 38px 20px;
  font-size: 12px !important;

  border-radius: 0 0 @border-radius-base @border-radius-base;
  background: linear-gradient(180deg, #fcfcff, #ffffff);

  .ant-select-item {
    font-size: 12px !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid #d9d9d9;
    height: 35px;

    .ant-btn {
      height: 33px;
      border: none;
      color: @strattic-dark-grey !important;
      span {
        font-family: 'Archivo', sans-serif !important;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .ant-pagination-item {
    min-width: 41px;
    height: 35px;
    line-height: 35px;
    a {
      color: @strattic-dark-grey;
      font-family: 'Archivo', sans-serif;
      font-weight: 500;
    }
  }

  .ant-pagination-options {
    .ant-select-selector {
      line-height: 35px !important;
      height: 35px;
      .ant-select-selection-item {
        line-height: 35px;
        color: @strattic-dark-grey !important;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}

.ant-table-cell {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  & .light-text {
    color: @strattic-dark-grey;
  }
}

.ant-table-column-sorters {
  padding: 21px 16px;
}

.ant-table-thead th.ant-table-column-sort {
  background: none;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: none;
}
