@import 'variables.less';

// For preventing Google Fonts flicker
html.wf-loading * {
  opacity: 0;
}

body {
  font-family: @strattic-font-family;
  font-weight: 500;
}

// Global App Styles Here
button[type='submit'] {
  margin: 15px auto 10px;
  display: block;
  min-width: 180px;
  font-size: 13px;
}

.primary-buttons {
  padding-top: 2px;
  padding-bottom: 10px;
  height: auto;
}

.ant-btn-primary.ant-btn-primary:focus {
  background: @strattic-red !important;
  border-color: @strattic-red !important;
}

.primary-add {
  font-size: 12px;
  font-weight: 700;
  height: 40px !important;
  width: 129px;
}

.ant-btn-primary.ant-btn-primary:focus {
  background: @strattic-blue;
  border-color: @strattic-blue;
}

.ant-card-bordered {
  border-color: @strattic-grey;
}

.ant-spin {
  color: @strattic-red;
}

.ant-spin-dot-item {
  background-color: @strattic-red;
}

.site-layout-content {
  padding: 50px 0 10px;

  h1:first-child {
    margin-bottom: 50px;
  }
}

.site-type-toggle {
  float: right;
  max-width: 300px;
  margin: -60px 0 40px;

  label {
    margin-right: 10px;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .buttons {
    background: @toggle-background;
    border-radius: @border-radius-base;
    padding: 7px 0 9px;
  }

  button,
  button:hover,
  button:active,
  button:visited,
  button:focus {
    padding-top: 5px;
    margin: 0 5px;
    background: transparent;
    border-color: @toggle-background;
    box-shadow: none;
    color: @strattic-dark-grey;

    &:hover {
      color: @strattic-darker-grey;
    }

    &.active,
    &:focus {
      background: white;
      color: @strattic-blue;
    }
  }
}

.ant-card.empty {
  text-align: center;
  padding: 3rem 0 4rem;
}

.ant-pagination {
  margin-top: 20px;

  .ant-pagination-disabled {
    display: none;
  }

  button .anticon svg {
    fill: @strattic-darker-grey;
  }

  .ant-pagination-item-active {
    border-color: @strattic-red !important;

    a {
      color: @strattic-red !important;
    }
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-prev button:hover,
  .ant-pagination-next button:hover {
    border-color: @strattic-darker-grey;
    color: black;
  }
}

.ant-table-thead > tr > th {
  color: @strattic-dark-grey;
  font-weight: normal;
}

.ant-table-column-sorter-down svg,
.ant-table-column-sorter-up svg {
  fill: @strattic-darker-grey;
}

.ant-table-column-sorter-down.active svg,
.ant-table-column-sorter-up.active svg {
  fill: @strattic-red;
}

.narrow {
  .icon-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 50%;
    margin: -60px auto 10px;
    box-shadow: 0 4px 16px @strattic-grey;

    svg {
      margin-top: 20px;
    }
  }
}

.data-list {
  width: 100%;

  .section-title {
    color: @strattic-dark-grey;
    margin: 25px 0 10px 2px;
  }

  section {
    background: white;
    border-radius: @border-radius-base;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: @box-shadow;

    dl {
      margin: 0;
      padding-top: 7px;
      padding-bottom: 17px;
      clear: both;
      border-bottom: 1px solid @strattic-grey;

      &:first-child {
        padding-top: 0;

        dd {
          padding-top: 3px;
        }

        dt {
          h3 {
            padding-top: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    dd {
      color: @strattic-dark-grey;
      margin-bottom: 0;
      padding-top: 10px;

      .ant-btn {
        height: 20px;
      }

      button:not([type='submit']) {
        border: none;
        background: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    strong dd {
      color: @strattic-dark;
    }

    dt {
      svg {
        margin-bottom: -6px;
      }
    }

    svg {
      height: 20px;
      margin-bottom: -1px;
      margin-left: 5px;
      width: 20px;
      color: white;
    }

    .ant-select-arrow svg {
      color: @strattic-dark;
      height: auto;
      width: auto;
    }

    .reveal svg {
      margin-bottom: -1px !important;
      margin-left: 7px;
    }

    button.reset-sftp {
      color: @strattic-red;
      clear: left;
      display: block;
      padding: 0;
      margin: 0;
      border: none;
    }

    button {
      margin: 0;
      padding: 0;
      box-shadow: none;
    }

    .reveal + button {
      margin-left: -2px;
    }
  }
}

.ant-checkbox-inner {
  border-radius: 0;
}

.ant-dropdown .ant-dropdown-menu {
  background-color: #ffffff !important;
  border-radius: @border-radius-small !important;
  box-shadow: @box-shadow !important;
  border-color: @strattic-border-grey !important;
  padding: 4px 14px;
}

// no chupchick for now
// .ant-dropdown-menu:after,
// .ant-dropdown-menu:before {
//   content     : '';
//   display     : block;
//   position    : absolute;
//   right       : 10px;
//   width       : 0;
//   height      : 0;
//   border-style: solid;
//   transform   : translate(-50%, calc(-100% - 5px));
// }

// .ant-dropdown-placement-bottomLeft .ant-dropdown-menu:after,
// .ant-dropdown-placement-bottomLeft .ant-dropdown-menu:before {
//   right: auto;
//   left : 25px;
// }

// .ant-dropdown-menu:after {
//   top         : 5px;
//   border-color: transparent transparent #fff transparent;
//   border-width: 5px;
// }

// .ant-dropdown-menu:before {
//   top         : 5px;
//   right       : 8px;
//   border-color: transparent transparent @strattic-border-grey transparent;
//   border-width: 6px;
// }

.ant-dropdown-menu-item {
  padding: 0px 10px !important;
  border-radius: @border-radius-small !important;
  min-width: 200px;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  margin: 10px 0 !important;
  transition: all 0ms !important;
  height: auto !important;
  line-height: normal !important;
}

.ant-dropdown-menu button {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 11px !important;
  font-weight: 500;
  height: 22px;
  transition: all 0ms !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f1fafb;
  color: @strattic-turquoise !important;
}

.ant-dropdown-menu-item a {
  padding: 1px 12px;
  transition: all 0ms !important;
}

.ant-dropdown-menu-item:hover a {
  color: @strattic-turquoise !important;
}

.ant-dropdown-menu-item:hover button {
  color: @strattic-turquoise !important;
}

.ant-dropdown-trigger .anticon-down {
  color: @strattic-turquoise;
}

.ant-layout-footer {
  padding: 24px 0;
}

@media (max-width: @screen-md-min) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: @screen-lg-min) {
  .hidden-lg {
    display: none !important;
  }
}

@media (max-width: @screen-xl-min) {
  .hidden-xl {
    display: none !important;
  }
}

@import 'buttons';
@import 'table';
