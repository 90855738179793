body {
  font-family: 'Archivo';
}

.mainBox {
  display: flex;
  position: relative;
  background: linear-gradient(90deg, #212945 0%, #303a5b 100%);
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mainBox__decorator {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -48%);
  -webkit-transform: translate(-55%, -48%);
}

.mainBox__inner {
  display: block;
  position: relative;
  max-width: 100%;
  padding: 0px 20px;
}

.mainBox__logo {
  display: block;
  margin-bottom: 40px;
}

.mainBox__logo svg {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.mainBox__box {
  display: block;
  padding: 46px 57px;
  width: max-content;
  max-width: 100%;
  border-radius: 16px;
  border: 1px solid #f8f8fa;
  background: #ffffff;
  text-align: center;
}

.mainBox__title {
  display: block;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
  color: #000000;
}

.mainBox__text {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.71;
  color: #2f395a;
  margin-bottom: 14px;
}

@media (max-width: 768px) {
  .mainBox__logo {
    margin-bottom: 30px;
  }

  .mainBox__box {
    padding: 30px;
  }

  .mainBox__title {
    font-size: 24px;
  }
}

