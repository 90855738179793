.ant-btn {
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  padding: 8px 15px;
}

.ant-btn[disabled] {
  background-color: @strattic-grey;
  border-color: @strattic-grey;
  color: #8b8ba3;
}
